import React, { memo, useEffect, useState } from "react"
import SwapPage from "../components/SwapPage"
import SwapForm from "../forms/SwapForm"
import Container from "../components/Container"
import { useSearchParams } from "react-router-dom"
import useMigration from "hooks/useMigration"

export enum Type {
  "SWAP" = "swap",
  "PROVIDE" = "provide",
  "WITHDRAW" = "withdraw",
}

const Swap = () => {
  const { isMigrationPage } = useMigration()
  const [searchParams, setSearchParams] = useSearchParams()

  const type = searchParams.get("type") as Type
  const tabs = {
    tabs: isMigrationPage
      ? [
        {
          name: "migration",
          title: "Migration",
        },
      ]
      : [
        { name: Type.SWAP, title: "Swap" },
      ],
    selectedTabName: isMigrationPage ? "migration" : type,
  }

  useEffect(() => {
    if (!isMigrationPage) {
      if (
        !type ||
        !Object.keys(Type)
          .map((key) => Type[key as keyof typeof Type])
          .includes(type)
      ) {
        searchParams.set("type", Type.SWAP)
        setSearchParams(searchParams, { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isMigrationPage])

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "28px",
        }}
      >
          <h1>CW20 LUNC & Native LUNC</h1>
          <div style={{ color: "#CD6141", fontSize: "16px" }}>
          </div>

          <h2 style={{ color: "#CD6141", fontSize: "20px" }}>
            1:1 Exchange
          </h2>
          <br></br>
      </div>



      <SwapPage>
        <>{type && <SwapForm type={type} tabs={tabs} />}</>
      </SwapPage>
    </Container>
  )
}

export default memo(Swap)
