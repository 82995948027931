import { Route, Routes, Navigate } from "react-router-dom"
import Dashboard from "./pages"
import PairPage from "./pages/Pair"
import Swap from "./pages/Swap"


export default () => (
  <Routes>
    <Route index element={<Swap />} />
    <Route path="/" element={<Swap />} />
    <Route path="/swap" element={<Swap />} />
    <Route path="/pairs/:address" element={<PairPage />} />
    <Route path="*" element={<Navigate to="/swap" replace />} />
  </Routes>
)
