import { Int, MsgExecuteContract } from "@terra-money/terra.js"
import { useAddress } from "hooks"
import { div, floor, times } from "libs/math"
import { toAmount } from "libs/parse"
import { Type } from "pages/Swap"
import { useEffect, useMemo, useState } from "react"
import { tokenInfos } from "./usePairs"

type Params = {
  from: string
  to: string
  amount: number | string
  type?: Type
  slippageTolerance?: string | number
}

const useTBC = (params: Params) => {
  const walletAddress = useAddress()
  const {
    from,
    to,
    type,
    amount: _amount,
    slippageTolerance,
  } = params
  const amount = Number(_amount)
  const debouncedAmount = useDebounce(amount, 500)
  const [isLoading, setIsLoading] = useState(false)
  const [msgs, setMsgs] = useState<
    (MsgExecuteContract[] | MsgExecuteContract)[]
  >([])
  const [autoRefreshTicker, setAutoRefreshTicker] = useState(false)
  const [simulatedAmounts, setSimulatedAmounts] = useState<number[]>([])

  // From api to smart contract
  let [currentSupply, setCurrentSupply] = useState(0)
  let [currentPrice, setCurrentPrice] = useState(0)
  let [currentReserve, setCurrentReserve] = useState(0)
  let [taxCollected, setTaxCollected] = useState(0)


  let currentMarketCap = 0
  const tokenInfo = tokenInfos.get(to)

  // Will calculate new values
  let tokensToMint = 0
  let reserveReturned = 0
  let newMarketCap = 0

  const profitableQuery = useMemo(() => {
    let coinDeposit = 0
    let coinDebit = 0
    let msg = undefined

    let newReserve
    let newSupply 
    let simulatedAmount = 0
    let rate = 0

    setIsLoading(true)
    if (!to || !debouncedAmount) {
      return undefined
    } else if (from === "uluna") {
      const taxPercentage = 0.0

      coinDeposit = debouncedAmount //* (1 - (taxPercentage + 0.0051)) // 0.0051 is chain tax
      newReserve = currentReserve + coinDeposit

      if (newReserve == 0.0) {
        return undefined
      }

      // since cwLUNC and LUNC are 1:1
      const newSupply = currentSupply + coinDeposit

      //tokens to mint depends on whether currentSupply is within current SIGMOID_CURVE bracket
      let tokensToMint = newSupply - currentSupply

      simulatedAmount = Math.floor(tokensToMint * 1000000) //cuz 6 decimals
      simulatedAmount = simulatedAmount < 0 ? 0 : simulatedAmount

      rate = debouncedAmount / tokensToMint
      rate = rate < 0 ? 0 : rate

      let spot_price = 1_000_000

      const buyAmount = Math.floor(debouncedAmount * 1000000) //cuz 6 decimals

      const executeMsg = {
        buy: {},
      }

      msg = new MsgExecuteContract(
        walletAddress,
        "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6",
        executeMsg,
        { uluna: buyAmount.toString() }
      )
    } else if (
      from ===
      "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6"
    ) {
      const taxPercentage = 0.0

      coinDebit = debouncedAmount
      newSupply = currentSupply - coinDebit //this is cwLUNC

      // since cwLUNC and LUNC are 1:1
      newReserve = currentReserve - coinDebit

      //Subtract to get reserve difference
      let reserveDelta = currentReserve - newReserve

      //subtract tax
      let reserveReturned = reserveDelta * (1 - (taxPercentage + 0.006)) // 0.0051 is chain tax

      //Convert to Uint128
      simulatedAmount = reserveReturned * 1000000 //cuz 6 decimals
      simulatedAmount = simulatedAmount < 0 ? 0 : simulatedAmount

      //cal Ave rate for this order
      rate = reserveReturned / coinDebit
      rate = rate < 0 ? 0 : rate

      const burnAmount = parseFloat(`${coinDebit}`) * 1000000
      msg = new MsgExecuteContract(
        walletAddress, //sender
        "terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6", //cwLUNC TBC contract
        { burn: { amount: burnAmount.toString() } } // execute msg for handle msg
      )
    } else {
      return undefined
    }

    const tokenRoutes: string[] = []
    setIsLoading(false)
    return {
      msg,
      simulatedAmount,
      tokenRoutes,
      price: rate.toString(),
      //currentSupply,
      //currentReserve,
      //currentPrice
    }
  }, [to, debouncedAmount, msgs, simulatedAmounts]) //end of useMemo

  useEffect(() => {
    const url =
      "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra10fusc7487y4ju2v5uavkauf3jdpxx9h8sc7wsqdqg4rne8t4qyrq8385q6/smart/eyJjdXJ2ZV9pbmZvIjp7fX0="
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        //console.log(text)
        const tbcData = JSON.parse(text)
        setCurrentSupply(Number(tbcData.data.supply) / 1000000)
        setCurrentPrice(Number(tbcData.data.spot_price) / 1000000)
        setCurrentReserve(Number(tbcData.data.reserve) / 1000000)
        setTaxCollected(Number(tbcData.data.tax_collected) / 1000000)
      })

    return
  }, [debouncedAmount, from, to, type, isLoading])

  useEffect(() => {
    const timerId = setInterval(() => {
      setAutoRefreshTicker((current) => !current)
    }, 30000)
    return () => {
      clearInterval(timerId)
    }
  }, [debouncedAmount, from])

  const result = useMemo(() => {
    if (!from || !to || !type || !debouncedAmount) {
      return { profitableQuery: undefined, isLoading: false }
    }
    return {
      isLoading,
      profitableQuery,
    }
  }, [debouncedAmount, from, isLoading, profitableQuery, to, type])

  return result
}

// Generic deBounce Hook ( https://usehooks.com/useDebounce/ )
function useDebounce(value: number, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export default useTBC
